<template>
<div class="input-template-category">
  <div class="input-category unselect" @click="open">카테고리를 선택해주세요</div>
  <div class="tags">
    <div class="tag" v-for="tag in selectedValue" :key="`tag-${tag.id}`">
      <span>{{ tag.name }}</span>
      <i class="material-icons" @click="removeTag(tag)">close</i>
    </div>
  </div>
  <sweet-modal ref="modal" overlay-theme="dark">
    <div class="modal-container">
      <div class="modal-title">카테고리 선택</div>
      <div class="modal-content">
        <div class="subtitle6 main">카테고리를 여러개 등록할 수 있습니다.</div>
        <div class="categories">
          <div class="modal-content-item" v-for="tag in modalCategories" :key="`tag-${tag.id}`">
            <input type="checkbox" :value="tag.id" v-model="tag.checked" :id="`tag-${tag.id}`">
            <label :for="`tag-${tag.id}`" class="unselect">{{ tag.name }}</label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="button is-primary" @click="accept">완료</button>
      </div>
    </div>
  </sweet-modal>
</div>
</template>
<script>
import { SweetModal } from 'sweet-modal-vue';
export default {
  name: 'InputTemplateCategory',
  components: {
    SweetModal
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      categories: [],
      selectedValue: [],
      modalCategories: []
    }
  },
  created() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      const { data } = await this.$axios.get('/public/template_category');
      this.categories = data;
      this.selectedValue = this.categories.filter(i => this.value.includes(i.id));
    },
    open() {
      const selectedIds = this.selectedValue.map(i => i.id);
      this.modalCategories = this.categories.map(i => ({
        id: i.id,
        name: i.name,
        checked: selectedIds.includes(i.id)
      }));
      this.$refs.modal.open();
    },
    removeTag(tag) {
      this.selectedValue = this.selectedValue.filter(i => i.id !== tag.id);
    },
    accept() {
      const selectedIds = this.modalCategories.filter(i => i.checked).map(i => i.id);
      this.selectedValue = this.categories.filter(i => selectedIds.includes(i.id));
      this.$emit('update:value', selectedIds);
      this.$refs.modal.close();
    }
  }
}
</script>
<style lang="stylus" scoped>
@import '~assets/css/lp_main'

.input-template-category
  position relative
  .input-category
    border 1px solid $gray1
    padding 12px 16px
    border-radius 8px
    cursor pointer
    color $sub4
    font-size 15px

.tags
  display flex
  flex-wrap wrap
  gap 8px
  padding 8px 0
  margin-top 8px
  .tag
    padding 8px 12px
    border-radius 50px
    background-color $gray3
    color $sub
    font-size 14px
    display flex
    align-items center
    gap 4px
    i
      cursor pointer
      font-size 16px

.modal-container
  display flex
  flex-direction column
  height 100%

.modal-title
  font-size 22px
  font-weight 700
  border-bottom 1px solid $gray1
  text-align left 
  padding 24px

.modal-content
  flex 1
  overflow-y auto
  padding 24px
  text-align left
.modal-footer
  padding 24px
  border-top 1px solid $gray1
  button
    width 100%
    height 52px
    line-height 52px
    font-size 16px
    font-weight 700

.categories
  display grid
  grid-template-columns repeat(2, 1fr)
  row-gap 8px
  column-gap 16px
  margin-top 12px
  .modal-content-item
    display flex
    align-items center
    gap 8px
    input[type="checkbox"]
      appearance none
      width 20px !important
      height 20px
      border 2px solid $gray1
      border-radius 4px
      background-color white
      display flex
      align-items center
      justify-content center
      cursor pointer
      &:checked
        background-color $primary
        border-color $primary
        &::after
          content '\e5ca'
          color white
          font-size 14px
          font-weight 700
          font-family 'Material Icons'
          
      margin 0
      width fit-content
      border 1px solid $gray1
      border-radius 4px
      padding 4px
    label
      font-size 14px
      color $sub
</style>
<style lang="stylus">
  .input-template-category
    .sweet-modal
      max-height 90vh
      overflow hidden
      height 100%
      border-radius 12px !important
    .sweet-modal .sweet-box-actions
      top 20px
      right 16px
    .sweet-modal.is-alert .sweet-content
      padding 0
      overflow hidden
      height 100%
      padding-bottom 0 !important
    .sweet-modal .sweet-content .sweet-content-content
      height 100%

  @media (max-width: 768px)
    .input-template-category
      .sweet-modal
        max-height 100vh    
        border-radius 0 !important
</style>

